.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  backdrop-filter: blur(6px);
  animation: fadeIn 0.3s ease-in-out;
  height: 100vh;
}

.section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  color: #0a4b3a;
}

.modalConfigSessao {
  width: 100%;
  height: fit-content;
  background: #fff;
  padding: 20px;
  animation: slideDown 0.3s ease-in-out;
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;

  /* remove o gap global aqui para melhor controle */
}

.modal {
  width: 100%;
  background: #fff;
  padding: 20px;
  animation: slideDown 0.3s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow-y: auto;
}


/* 🔻 Rodapé fixado na base da modal */
.modalActions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-top: 12px;
  margin-top: auto;
}

.modalActionsPresenca {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: auto;
}

.forms {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.formResumo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

  /* 🔹 Pauta Grupo */
  .pautaGrupo {
    background: #fff;
    border-radius: 8px;
    border-left: 5px solid #1ca882;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
    max-height: 400px;
    overflow-y: auto;
  }
  
  /* 🔹 Estilização da barra de rolagem */
  .pautaGrupo::-webkit-scrollbar {
    width: 10px;
  }
  
  .pautaGrupo::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .pautaGrupo::-webkit-scrollbar-thumb {
    background: #1ca882;
    border-radius: 10px;
  }
  
  .pautaGrupo::-webkit-scrollbar-thumb:hover {
    background: #1ca882;
  }
  
  .pautaGrupo li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .pautaGrupo li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
    .pautaGrupo h3 {
      font-size: 18px;
      color: #11503f;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 6px;
    }
    

/* 🔹 Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(0);
  }
}

/* 🔹 Título do Modal */
.modalTitle {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: #1d715b;
  border-bottom: 3px solid #1d715b;
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.modalTitle svg {
  margin-right: 12px;
  color: #1ca882;
}

/* 🔹 Botão de Fechar */
.modalClose {
  position: absolute;
  top: 12px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.9rem;
  cursor: pointer;
  color: #1ca882;
  transition: color 0.3s ease-in-out;
  padding: 20px;
}

.modalClose:hover {
  color: #626161;
}

/* 🔹 Formulário */
form {
  font-size: 1.5rem;
  gap: 12px;
}

/* 🔹 Duas colunas para campos menores */
.row-two {
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row-two .inputGroup {
  flex: 1;
  min-width: 150px;
}

/* 🔹 Agrupamento dos Inputs */
.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* 🔻 Conteúdo do formulário, torna rolável */
.formPautaAdd {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px; /* espaçamento entre os grupos */
  padding-right: 8px;
  color: #0a4b3a;
}

/* 🔻 Conteúdo do formulário, torna rolável */
.formPauta {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px; /* espaçamento entre os grupos */
  padding-right: 8px; /* espaço pro scrollbar */
  max-height: 400px;
  overflow-y: auto;
  color: #0a4b3a;
}

/* 🔹 Estilização da barra de rolagem do formulário */
.formPauta::-webkit-scrollbar {
  width: 10px;
}

.formPauta::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.formPauta::-webkit-scrollbar-thumb {
  background: #1ca882;
  border-radius: 10px;
}

.formPauta::-webkit-scrollbar-thumb:hover {
  background: #1ca882;
}

.formPauta span {
  font-size: 0.9rem;
}

/* 🔹 Labels */
label {
  font-size: 0.85rem;
  font-weight: bold;
  color: #11503f;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

/* 🔹 Ícones nos Labels */
label svg {
  margin-right: 10px;
  color: #1d715b;
}

/* 🔹 Inputs e Selects */
input,
select {
  background-color: #d4efe9;
  padding: 6px !important;
  border-radius: 3px !important;
  font-size: 1rem;
  outline: none !important;
  transition: border-color 0.3s !important;
  border: none;
}

input:focus,
select:focus {
  border-color: #1ca882;
}

/* 🔹 Melhorando o Upload de Arquivo */
.fileInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f0f0f0;
  padding: 12px;
  border-radius: 3px;
  cursor: pointer;
  transition: background 0.3s;
}

.fileInput:hover {
  background: #e0e0e0;
}

/* 🔹 Melhorando Textarea */
textarea {
  height: fit-content;
  margin-top: 0 !important;
  padding: 12px;
  border-radius: 3px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s;
  resize: vertical;
  font-family: "Arial", sans-serif;
  line-height: 1.5;
  background: #f8f8f8;
}

textarea:focus {
  border-color: #1ca882;
  background: #fff;
}


.modalActionsPresensa button {
  min-width: 32%;
  padding: 12px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.modalActions button {
  width: 50%;;
  padding: 14px 20px;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.modalActions button svg {
  font-size: 16px;
}

.attachmentLink {
  background: #e9f7f4;
  padding: 10px 14px;
  border-radius: 6px;
  font-weight: bold;
  color: #1d715b;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: background 0.2s ease-in-out;
}

.attachmentLink:hover {
  background: #d4efe9;
}

/* Cores dos Botões */
.btnSim {
  background: #28a745;
  color: white;
}

.btnAvancar {
  background: #28a745;
  color: white;
}

.btnSalvar {
  background: #28a745;
  color: white;
}

.btnVoltar {
  background: #11503f;
  color: white;
}

.btnNao {
  background: #dc3545;
  color: white;
}

.btnCancelar {
  background: #dc3545;
  color: white;
}

.btnAbstencao {
  background: #ffc107;
  color: #333;
}

/* 🔹 Títulos das Seções */
h1 {
  font-size: 1rem;
  font-weight: bold;
  color: #1d715b;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #1d715b;
}

/* 🔹 Títulos das Seções */
h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1d715b;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #1d715b;
}

/* 🔹 Títulos das Pautas */
h3 {
  font-size: 1.2rem;
  color: #1d715b;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 10px;
  border-left: 4px solid #1ca882;
}

/* 🔹 Botão de Salvar */
button[type="submit"] {
  background: #1d715b;
}

button[type="submit"]:hover {
  background: #11503f;
}

/* 🔹 Botão de Cancelar */
button.cancel {
  background: #ff4d4d;
  color: white;
}

button.cancel:hover {
  background: #b30000;
}

.resumoSessao {
  display: flex;
  flex-wrap: wrap !important;
  flex-direction: none !important;
  justify-content: space-between;
  align-items: left;
  gap: 12px;    
}

.resumoSessao p {
  background: #fff;
  border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
}

.uploadButton {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.uploadButton:hover {
  background-color: #0056b3;
}

.uploadButton .icon {
  margin-right: 8px;
  font-size: 16px;
}

.modalSection {
  padding: 12px;
  background: #f9f9f9;
  border-radius: 8px;
}

.modalCreateSection {
  padding: 12px;
  background: #f9f9f9;
  border-radius: 8px;
}

.modalSection h3 {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.textBlock {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 15px;
  line-height: 1.6;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 250px;
  overflow-y: auto;
  color: #333;
}

.status {
  font-weight: bold;
  color: #007bff;
}

.downloadLink {
  display: flex;
  align-items: center;
  background: #007bff;
  color: white;
  padding: 10px;
  border-radius: 6px;
  text-decoration: none;
  transition: background 0.3s;
}

.downloadLink:hover {
  background: #0056b3;
}

.downloadLink svg {
  margin-right: 8px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  max-width: 500px;
  gap: 12px;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.3);
  animation: slideDown 0.3s ease-in-out;
  position: relative;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-confirm {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
}

.btn-cancel {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.autorTag {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  justify-items: center;
}


@media (max-width: 768px) {
  .inputGroup {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

/* Container do Seletor */
.seletorContainer {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 12px;
  color: #0a4b3a;
  flex-wrap: wrap; /* Para garantir que o layout se ajuste em telas pequenas */
}

/* Cards do Seletor */
.seletorCard {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 8px 12px;
  border-radius: 8px;
  transition: background-color 0.2s ease, transform 0.2s ease;
  background: #e9f7f4;
  border: 2px solid transparent;
  user-select: none; /* Para evitar que o texto seja selecionado */
}

/* Hover nos cards */
.seletorCard:hover {
  transform: scale(1.05);
  background-color: #d8f3e6;
}

/* Estilizando o card ativo */
.seletorCard.ativo {
  border: 2px solid #0d6efd;
  background: rgba(13, 110, 253, 0.1);
}

.acoesDetalhes {
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: flex-start;
  width: fit-content;
}

.acoesDetalhes button, .dashboardButton button, .acoesDetalhes a {
  background: #1ca882;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 12px 12px;
  transition: color .3s ease;
}